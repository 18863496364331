import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MDXRenderer } from "gatsby-plugin-mdx";

const useStyles = makeStyles(theme => ({
  descriptif: {
    "& p": {
      fontWeight: 500,
      textAlign: "start",
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
      [theme.breakpoints.down('xs')]: {
        // fontSize: theme.spacing(1.5),
      },
    },
    "& ul": {
      fontWeight: 500,
      textAlign: "start",
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        // fontSize: theme.spacing(1.5),
      },
    },
    "& li": {
      fontSize: theme.spacing(1.5),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  notaBene: {
    borderTop: `1px solid ${theme.palette.yellow.main}`,
    borderBottom: `1px solid ${theme.palette.yellow.main}`,
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0)}px`,
    "& p": {
      fontSize: `${theme.spacing(1.5)}px`,
      paddingBottom: `${theme.spacing(1)}px`,
    },
  },
}));

const Descriptif = ({ refModel, refStyle, remark }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    {
      allMdx(filter: {fileAbsolutePath: {regex: "/content/descriptifs/"}}) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              refModel
            }
            body
          }
        }
      }
    }
  `);
  const getModelDescription = (ref) => {
    const reference = data.allMdx.edges.find(({ node: { frontmatter: { refModel } }})=> refModel === ref );
    return reference.node.body;
  }
  return (
    <Grid item xs={12} md={12} lg={10}>
      <div className={classes.descriptif}>
        <MDXRenderer>{getModelDescription(`${refModel}`)}</MDXRenderer>
      </div>
      <div className={classes.notaBene}>
        <Typography variant="body2">
          Tous les matériaux utilisés (hors fils de coutures, élastiques, œillets et anneaux) 
          sont de seconde main, sourcés dans les ressourceries, vides-greniers, Le bon coin… 
        </Typography>
        <Typography variant="body2">
          La plupart des tissus sont en coton ; 
          nous nous efforçons à sourcer des matériaux bio-dégradables.
          Pour autant, certains contiennent un peu d'élasthane et
          une petite partie peuvent être en polyester. 
          Tout ceci reflête ce qui se trouve sur le marché de la seconde main, 
          et donc de nos achats antérieurs.
        </Typography>
      </div>
    </Grid> 
  ) 
};
export default Descriptif;
