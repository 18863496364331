import React from "react";
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MDXRenderer } from "gatsby-plugin-mdx";
import { 
  Button, 
  Grid,
  Typography 
} from '@material-ui/core';
import Layout from '../Layout';
import Descriptif from '../Descriptif';
import meche from '../../assets/images/meche.svg';
import vesteDimension from '../../assets/images/veste-Dimensions.png';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      justifyContent: "flex-end",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  pictures: {
    margin: "0 !important",
    // display: "flex",
    // flexShrink: 2,
    // backgroundColor: "yellow",
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: "row",
      flexWrap: "nowrap", 
      transform: "translateX(-2.5%)",
      width: "300vw",
      scrollSnapType: "x mandatory",
    },
  },
  media: {
    margin: "0 !important",
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
      padding: "0 !important",
      scrollSnapAlign: "start",
    },
  },
  col_1: {
    position: "relative",
    alignSelf: "center",
    [theme.breakpoints.down('lg')]: {
      alignSelf: "start",
    },
    [theme.breakpoints.down('xs')]: {
      width: "100vw",
      overflowX: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar ": {
        display: "none",
      },
    },
  },
  col_2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      flexDirection: "column",
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    alignContent: "center",
    justifyContent: "center",
    margin: `${theme.spacing(0)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('lg')]: {
      margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      minWidth: theme.spacing(12),
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: "start",
      minWidth: theme.spacing(11),
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
      flexDirection: "row",
      alignItems: "center",
      maxWidth: "100%",
    },
  },
  heading_name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    alignContent: "center",
    padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    textAlign: "center",
    [theme.breakpoints.down('lg')]: {
    },
    [theme.breakpoints.down('md')]: {
      "& h2": {
        fontSize: theme.spacing(4),
      },
      "& h3": {
        fontSize: theme.spacing(3.5),
      },  
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      writingMode: "initial",
      textOrientation: "initial",
      order: 2,
      alignItems: "flex-start",
      "& h2": {
        fontSize: theme.spacing(3.75),
      },
      "& h3": {
        fontSize: theme.spacing(3.25),
      },  
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      "& h2": {
        fontSize: theme.spacing(2.75),
      },
      "& h3": {
        fontSize: theme.spacing(2.25),
      },  
    },
  },
  pricing: {
    [theme.breakpoints.down('md')]: {
      height: "auto",
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
      padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      height: theme.spacing(7.5),
    },
  },
  heading_price_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    alignContent: "stretch",
    justifyContent: "stretch",
    // [theme.breakpoints.down('sm')]: {
    //   flexDirection: "row",
    // },
  },
  heading_price: {
    padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(.5)}px ${theme.spacing(0)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    }
  },
  heading_oldPrice: {
    textDecoration: "line-through",
    opacity: .5,
  },
  badge: {
    position: "absolute",
    zIndex: 1,
    top: "1%",
    right: "1%",
    borderRadius: 100,
    height: `${theme.spacing(5)}px`,
    width:`${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: { 
      height: `${theme.spacing(5)}px`,
      bottom: "87%",
      left: "75%",    }
  },
  badge_text: {
    position: "absolute",
    margin: `${theme.spacing(2.5)}px`,
    transform: "translate(-50%,-50%) ",
    verticalAlign: "middle",
    textAlign: "center",
  },
  badge_discount: {
    backgroundColor: theme.palette.orange.main,
  },
  badge_new: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
    fontStyle: "italic",
  },
  heading_separator: {
    width: "3.5vw",
    [theme.breakpoints.down('sm')]: { 
      width: "5vw",
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`
    },
    [theme.breakpoints.down('xs')]: { 
      width: "7.5vw",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textAlign: "start",
  },
  button_return: {
    textDecoration: ` underline 1px solid ${theme.palette.primary.main}`,
    // border:  
    // borderRadius: 0,
    margin: `0 0 0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(4)}px 0 0 0`,
    },
  },
  buttons_container: {
    display: "flex", 
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
}));

const Product = ({ data: { mdx } }) => {
  const classes = useStyles();
  return (
    <Layout pageName={mdx.fields.slug}>
      <Grid 
        container 
        spacing={0} 
        direction="row"
        alignItems="start"
        justifyContent="center"
        className={classes.root}
      >
        <Grid item xs={12} sm={3} md={5} lg={5} className={classes.col_1}>
        {mdx.frontmatter.discount > 1  && 
          <div className={clsx(classes.badge, classes.badge_discount)}>
            <Typography 
              variant="body2" 
              className={classes.badge_text}
            >
              {`-${mdx.frontmatter.discount}%`}
            </Typography>
          </div>
                      }
        {mdx.frontmatter.isNew  && 
          <div className={clsx(classes.badge, classes.badge_new)}>
            <Typography 
              variant="body2" 
              className={clsx(classes.badge_text)}
            >
              New!
            </Typography>
          </div>
        }
          <Grid container direction="row" spacing={0} className={classes.pictures}> 
            {mdx.frontmatter.pictures.map((node, index)=> 
              <Grid 
                item 
                xs={12} md={6}
                key={`${mdx.frontmatter.name}-${index}`}
                className={classes.media}
              > 
                <Img
                  fluid={node.childImageSharp.fluid}
                  key={node.childImageSharp.fluid}
                  alt={`${mdx.frontmatter.name}-${index}`}
              />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={7} lg={7} className={classes.col_2}>
        <div className={classes.heading}>
          <div className={classes.heading_name}>
            <Typography 
              variant="h3"
              variantMapping={{h3: "h2"}} 
            >
              {mdx.frontmatter.name}
            </Typography>
          </div>
          <div className={classes.pricing}>
            <img className={classes.heading_separator} src={meche} alt="separator" />
            <div className={classes.heading_price_container}>
              {mdx.frontmatter.discount > 1 &&                
                <Typography 
                  className={classes.heading_oldPrice}
                  variant="body1"
                >
                  {`${mdx.frontmatter.price.slice(0,-3)}€`}
                </Typography>
              }
              <Typography 
                variant="subtitle1" 
                className={classes.heading_price}
              >
                {`${mdx.frontmatter.price -((mdx.frontmatter.discount/100)*mdx.frontmatter.price)} €`}
              </Typography>
            </div>
            <img className={classes.heading_separator} src={meche} alt="separator" />
          </div>
        </div>
          <div className={classes.content}>
            {mdx.frontmatter.refModel === "07" && 
              <>
                <Typography variant="h6">Taille</Typography>
                  <Typography variant="body1">
                  <MDXRenderer>{mdx.body}</MDXRenderer>
                  <img src={vesteDimension}/>
                </Typography>
              </>
            }
            <Descriptif 
              refModel={mdx.frontmatter.refModel}
              refStyle={mdx.frontmatter.refStyle}
              // remark={mdx.frontmatter.remark}
            />
            <div className={classes.buttons_container}>
              {mdx.frontmatter.inlineAvailable ?
              <Button 
                target="_blank" 
                rel="noreferrer"
                href="mailto:contact@surboom.fr"
                variant="outlined"
                label=""
              >
                Obtenir un lien de paiement sécurisé
              </Button>
              :
              <Typography variant="h4">{mdx.frontmatter.location.length > 1 ? <Typography variant="body2">disponible ici : {mdx.frontmatter.location}</Typography> : `vendu !`}</Typography>
              }
              <Button 
                className={clsx(classes.button, classes.button_return)}
                // variant="outlined"
                href="/"
              >
                Retourner à l'accueil
              </Button>
            </div>
          </div>
        </Grid>
        </Grid>
  </Layout>
  )
}
export default Product;

export const ProductTemplateQuery = graphql`
  query ProductTemplateQuery($slug: String) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      body
      frontmatter {
        name
        price
        discount
        isNew
        refModel
        refStyle
        weight
        inlineAvailable
        location
        pictures {
          absolutePath
          publicURL
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`